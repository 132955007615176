<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import DatePicker from "vue2-datepicker";
import WancoinContent from '@/components/WancoinContent'

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data() {
    return {
      title: "查詢旺幣兌換清單",
      items: [
        {
          text: "用戶等級任務管理",
          href: "/usermanagement",
        },
        {
          text: "查詢旺幣兌換清單",
          active: true,
        }
      ],

      currentPromotionalItemHashID: null,
      currentPromotionalItem: '',
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "name",
          label: "姓名",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "code",
          label: "會員編號",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "type",
          label: "好朋友等級",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "count",
          label: "兌換數量",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "phone",
          label: "手機號碼",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "email",
          label: "email",
          sortable: false,
          class: 'width-auto center'
        }
      ]
    };
  },
  computed:{
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.currentPromotionalItemHashID = this.$route.params.promotionalItemHashID
    this.currentPromotionalItem = this.$route.query.name + ' ' + this.$route.query.price + '元'
    this.searchList()
  },
  methods: {
    searchList (_table, _callback) {
      const vm = this

      const searchParam = {
        page: this.currentPage || 1,
      }
      this.$root.apis.getWancoinCouponExchangeList(this.currentPromotionalItemHashID, searchParam, 
        function (_response) {
          let response = _response.body.data
          vm.totalRows = response.total
          vm.currentPage = response.page
          vm.perPage = response.per_page
          if (typeof _callback === 'function') {
            vm.tableData = response.items
            _callback(vm.tableData)
          }
        },
        function (_error) {
          console.error(_error)
          if (typeof _callback === 'function') {
            _callback([])
          }
        })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
  components: {
    Layout,
    PageHeader,
    DatePicker,
    WancoinContent
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      b-button.width-md.mb-3(variant="secondary" to="/wancionexchange") 回上一層      
      //- 表格
    .col-lg-12
      .card
        .card-body
          .header-row.mb-3
            .header-title {{ currentPromotionalItem }} - 兌換名單
            div
              span.mr-2 符合條件資料共計有 {{ totalRows }} 筆資料
              b-button.ml-1.width-lg(variant="primary") 匯出
          //- Table
          .table-responsive.mb-0
            b-table(
              head-variant="light"
              ref="searchTable"
              :items="searchList",
              :fields="fields",
              responsive="sm",
              :per-page="perPage",
              :current-page="currentPage",
              :sort-desc.sync="sortDesc",
              :filter="filter",
              :filter-included-fields="filterOn",
              @filtered="onFiltered")

              template(v-slot:cell(code)="data")
                b-button.table-btn(:to="`/usermanagement/setting/${data.item.id}`") {{ data.item.code }}

              template(v-slot:cell(type)="data")
                span(v-if="data.item.type === 1") 中天好朋友
                span(v-else-if="data.item.type === 2") 麻吉好朋友
                span(v-else-if="data.item.type === 3") 超級好朋友

              template(v-slot:cell(phone)="data")
                span(v-if="data.item.phone") {{ data.item.phone }}
                span(v-else) -

              template(v-slot:cell(email)="data")
                span(v-if="data.item.email") {{ data.item.email }}
                span(v-else) -


          .row.mb-md-2
            .col-12.row.d-flex.justify-content-end  
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>
<style lang="sass" scoped>
.header-row
  display: flex
  justify-content: space-between
  align-items: center

::v-deep .action-flex
  display: flex
  justify-content: flex-start
  align-items: center

::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  &.delete
    color: #333333

::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
</style>
